import './css/App.css';
import AdminSignup from './AdminSignup';
import AdminLogin from './AdminLogin';
import axios from 'axios';
import React,{ useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import MeetingAvailabilityForm from './MeetingAvailabilityForm';
import EmergencyReport from './EmergencyReport';
import VisitorManagement from './VisitorManagement';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import SyncLoader from "react-spinners/SyncLoader";
import { PiWarningCircle } from 'react-icons/pi';

function App() {
  
  const[adminEmail, setAdminEmail]= useState('')
  const[isServerConnect, setIsServerConnect]= useState(false)

  // handle change display employee 
  const handleAdminEmail = (email) => {
      setAdminEmail(email)
  }

  useEffect(() => {
  const checkServerStatus = async() => {
    try {
      const response = await axios.get("https://viztrack-server.onrender.com/api/server/connect");
      if (response.data === "server connected") {
        setIsServerConnect(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!isServerConnect) {
    // Call the function initially
    checkServerStatus();

    // Set up an interval to check the server status repeatedly
    const intervalId = setInterval(() => {
      checkServerStatus();
    }, 5000); 

    // Clean up the interval when the component unmounts or isServerConnect becomes true
    return () => {
      clearInterval(intervalId);
    };
  }
}, [isServerConnect]);
  
  return (
    <div className="App">
    { !isServerConnect ?
      (<div className='server-loading'>
        <div className='server-connection-loader'>
            <SyncLoader color={'#5d8ee7'} loading={!isServerConnect} size={25} />
        </div>
        <div className='server-connection-warning'>
          <PiWarningCircle size={20}/>
          <p>Server run on a free instance type will spin down with inactivity, so please be patient for the initial server connection<br/>( Refresh the page if it is still loading after 3 minutes )</p> 
        </div>
      </div>) :
      (<BrowserRouter>
        <Routes>
          <Route path="/viztrack/*" element={<VisitorManagement />} />
          <Route path="" element={<Navigate to="/admin/login" />} />
          <Route path="/availability/form" element={<MeetingAvailabilityForm />} />
          <Route path="/emergency/report" element={<EmergencyReport />} />
          <Route path="/admin/login"  element={<AdminLogin onChangeAdminEmail={handleAdminEmail} serverConnect={isServerConnect}/>} />
          <Route path="/admin/signup" element={<AdminSignup />} />
          <Route path="/admin/forgot/password" element={<ForgotPassword onChangeAdminEmail={handleAdminEmail} AdminEmailID={adminEmail} />} />
          <Route path="/admin/change/password" element={<ChangePassword onChangeAdminEmail={handleAdminEmail} AdminEmailID={adminEmail}/>} />
          <Route path="/emergency/report" element={<EmergencyReport />} />
        </Routes>
       </BrowserRouter>)
     }
    </div>
  );
}

export default App;
